import { createContext, ReactNode, useContext, useState } from 'react';

export type UIContextType = {
    showMenu: boolean;
    setShowMenu: (showMenu: boolean) => void;
}

export const UIContext = createContext<UIContextType>({
    showMenu: false,
    setShowMenu: () => {},
});

export const useUIContext = () => useContext(UIContext);

export type UIProviderProps = {
    children: ReactNode;
}

export const UIProvider = ({ children } : UIProviderProps) => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <UIContext.Provider value={{
            showMenu,
            setShowMenu
        }}>
            { children }
        </UIContext.Provider>
    )
}
