import { useEffect } from "react";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import * as ga from "../lib/ga";
import { UIProvider } from "../contexts/UIContext";

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = (url: any) => {
            ga.pageview(url);

            const id = "adplugg-adjs";
            const fjs = document.getElementsByTagName("script")[0];
            const js = document.createElement("script");
            js.id = id;
            js.src = "//www.adplugg.com/serve/A48221174/js/1.1/ad.js";
            // @ts-ignore
            fjs.parentNode.insertBefore(js, fjs);
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    return (
        <UIProvider>
            <Component {...pageProps} />
        </UIProvider>
    );
}

export default MyApp;
