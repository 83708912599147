export const pageview = (url) => {
    window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
    });
};

export const event = (action, params) => {
    window.gtag("event", action, params);
};

export const loadAds = () => {
    let ads = document.getElementsByClassName("adsbygoogle");
    const adCount = ads.length;
    for (let i = 0; i < adCount - 1; i++) {
        try {
            // @ts-ignore
            if (ads[i].dataset.adsbygoogleStatus !== "done") {
                // @ts-ignore
                (adsbygoogle = window.adsbygoogle || []).push({});
            }
        } catch (e) {
            console.log(e);
        }
    }
};
