// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn:
        SENTRY_DSN ||
        "https://c3524428a4784407b656b40367152229@o1362152.ingest.sentry.io/6653415",
    tracesSampleRate: 0.01,
    ignoreErrors: [
        /pagead\/js\/adsbygoogle/i,
        /Blocked a frame with origin \"https:\/\/wiadomosci.co.uk\" from accessing a cross-origin frame. Protocols, domains, and ports must match./i,
        /Failed to read the 'localStorage' property from 'Window': Access is denied for this document./i,
    ],
});
